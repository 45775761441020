.modal {
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal.viewActivity {
  justify-content: flex-end;
}

/* Modal Content */
.modalContent {
  background-color: #fefefe;
  /* margin: auto; */
  padding: 1.5rem;
  border-radius: 10px;
  width: 80%;
  max-width: 1000px;
  position: relative;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  max-height: 80vh;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.modalContent::-webkit-scrollbar {
  display: none;
}
.modalContent.viewActivity {
  width: 400px;
  height: 100% !important;
  border-radius: 0;
  justify-content: flex-start;
  max-height: none;
}

.activityHeader {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
  border-bottom: 1px solid #e6e6e6;
}

.activityHeader p {
  font-size: smaller;
  display: flex;
  align-items: center;
}

.activitysContainer {
  display: flex;
  flex-direction: column;
  padding: 1rem 1.4rem;
  position: relative;
  height: 85%;
}
.logsConatiner {
  height: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.logsConatiner::-webkit-scrollbar {
  display: none;
}

.modalContent.confirmation {
  max-width: 550px;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  position: absolute;
  top: 5px;
  right: 15px;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  width: 100%;
}

.actionButton {
  background-color: var(--lightOrange);
  color: white;
  padding: 0.6rem 1rem;
  border-radius: 5px;
  border: 0;
  outline: 0;
  cursor: pointer;
}
.cancelButton {
  color: var(--select-input-placeholder);
  padding: 0.6rem 1rem;
  border-radius: 5px;
  border: 2px solid var(--select-input-placeholder);
  outline: 0;
  background-color: white;
  cursor: pointer;
}

.modalTitle {
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  font-size: large;
  font-weight: 600;
  color: var(--sih_blue);
}

.infoConatiner {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: flex-start;
  width: 100%;
}
.leftColumn,
.rightColumn {
  width: 45%;
}

.infoGroup {
  display: flex;
  flex-direction: column;
  gap: 1px;
  justify-content: center;
  margin-bottom: 1rem;
}
.infoGroup label {
  font-size: x-small;
  font-weight: 600;
  color: #000;
}
.infoGroup div {
  border-bottom: 1px solid black;
  font-size: small;
  min-width: 290px;
}
.buttonContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  padding: 0 1rem;
}
.rejectButton {
  padding: 0.4rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  background-color: var(--redColor);
  font-size: small;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
}

.approveButton {
  padding: 0.4rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  background-color: var(--sih_blue);
  font-size: small;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
}

.previewLogo div {
  border: none !important;
}
.schoolLogoPreview {
  max-width: 120px;
}
.ConcentPreview {
  color: var(--lightOrange);
  font-weight: 600;
  padding-top: 5px;
}

.modalDescription {
  font-size: small;
  width: 100%;
  margin-top: -0.6rem;
}

.wizardConatiner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.individualFormConatiner {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.formGroup {
  display: flex;
  flex-direction: column;
  gap: 5px;
  /* min-width: 350px; */
  width: 48%;
  position: relative;
}

@media (max-width: 768px) {
  .formGroup {
    width: 100%;
  }
  .leftColumn,
  .rightColumn {
    width: 100%;
  }
  .infoConatiner {
    flex-direction: column;
  }
  .previewEmailContentBox {
    width: fit-content;
  }
}
.formInput,
select {
  border-radius: 4.545px;
  border: 0.909px solid #d9d9d9;
  outline: none;
  padding: 0.6rem 1rem;
}

.formGroup label {
  background-color: white;
  padding: 6px;
  font-size: x-small;
  position: absolute;
  top: -14px;
  left: 8px;
  z-index: 1;
}
.formGroup label span {
  color: red;
}

.previewContainer {
  display: flex;
  justify-content: space-between;
  font-size: x-small;
}

.previewContainer a {
  color: blue;
  text-decoration: underline;
}

.rejectForm {
  width: 100%;
}
.rejectForm p {
  font-size: 1.2rem;
  color: #000;
  margin-bottom: 1.5rem;
  font-weight: 600;
}
.textOnInput {
  position: relative;
}
.textOnInput label {
  position: absolute;
  top: -11px;
  left: 23px;
  padding: 2px;
  z-index: 1;
}
.textOnInput label:after {
  content: " ";
  background-color: #fff;
  width: 100%;
  height: 13px;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
}
label {
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 0.5rem;
  color: var(--input-lable-color);
}
.form_control {
  display: block;
  width: 100% !important;
  height: 54px;
  padding: 14px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: var(--input-placeholder);
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
}

.rejectForm .buttonContainer {
  margin-top: 2rem;
}
.rejectForm .buttonContainer {
  margin-top: 2rem;
}
.rejectFormCancelButton {
  padding: 0.4rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid var(--lightOrange);
  font-size: small;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: var(--lightOrange);
}

.textLink {
  color: blue;
  font-size: small;
  cursor: pointer;
  text-decoration: underline;
}

.IdeaNameConatiner li::marker {
  color: #f69e45;
}
.IdeaNameConatiner ul {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: #545454;
  font-size: 14px;
}
.IdeaNameConatiner {
  max-height: 400px;
  overflow-y: auto;
  width: 100%;
  padding: 1rem 2rem;
}
.IdeaNameConatiner::-webkit-scrollbar {
  width: 20px;
}

.IdeaNameConatiner::-webkit-scrollbar-track {
  background-color: transparent;
}

.IdeaNameConatiner::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

.IdeaNameConatiner::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}
.tooltipText {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  width: 200px;
  color: white !important;
  font-size: 12px;
  background-color: var(--lightOrange);
  border-radius: 10px;
  padding: 10px 15px 10px 15px;

  top: -8px;
  left: 120%;
}

.hoverText:hover .tooltipText {
  visibility: visible;
}

.hoverText {
  top: 1px;
  position: relative;
  color: var(--input-lable-color) !important;
}

.hoverText svg path {
  fill: var(--input-lable-color) !important;
}

.testMailDiv {
  width: 75%;
  text-align: center;
}
.testMailTitle {
  font-size: 1.3rem;
  font-weight: 700;
  margin-bottom: 1rem;
}
.emailIdsBox {
  width: 100%;
}
.emailIdsBox textarea {
  width: 100%;
  margin-bottom: 2rem;
  resize: none;
}
.emailIdsBox .actionButtons {
  gap: 1.5rem;
  justify-content: center;
}

.previewMailCount {
  font-weight: 700;
}
.previewMailSubject {
  font-weight: 500;
  margin: 1rem 0rem;
}

.previewEmailContentBox {
  background-color: white;
  padding: 1.5rem;
  border-radius: 10px;
  width: fit-content;
  box-shadow: 0px 0px 6px 2px #d9d9d9;
  margin: 1.5rem auto;
  max-height: 350px;
  overflow-y: auto;
}
.previewEmailContentBox p {
  font-size: small;
}
.previewMail {
  text-align: center;
}
.previewMail .actionButtons {
  gap: 1.5rem;
  justify-content: center;
  margin-top: 1.5rem;
}

.statConatiner {
  display: flex;
  padding: 5px;
  border-bottom: 1px solid #d9d9d9;
  width: fit-content;
  font-size: smaller;
}
@media (max-width: 600px) {
  .statConatiner {
    font-size: x-small;
  }
}
.stat {
  margin: 6px;
  padding: 0 6px;
  display: flex;
  border-right: 1px solid #d9d9d9;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
}
.stat:last-child {
  border-right: none;
}
.statNumber {
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(246, 158, 69, 0.2);
  border-radius: 3px;
  color: var(--dark-orange);
}
.individualForm {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  width: 90%;
  position: relative;
  padding: 2rem;
  border: 1px solid #d9d9d9;
  width: 100%;
  border-radius: 10px;
}

.formGroup {
  display: flex;
  flex-direction: column;
  gap: 5px;
  /* min-width: 350px; */
  width: 48%;
  position: relative;
}
@media (max-width: 768px) {
  .formGroup {
    width: 100%;
  }
}
.formInput,
select {
  border-radius: 4.545px;
  border: 0.909px solid #d9d9d9;
  outline: none;
  padding: 0.6rem 1rem;
}

.formGroup label {
  background-color: white;
  padding: 6px;
  font-size: x-small;
  position: absolute;
  top: -14px;
  left: 8px;
}
.formGroup label span {
  color: red;
}

.buttonContainer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
}

.overallFormHeading {
  font-size: small;
  font-weight: 600;
  width: 100%;
}

.buttonConatiner {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.memberForm {
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  padding: 1rem;
}

.addAnother {
  border-radius: 4.541px;
  border: 0.908px dashed #afafaf;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.4rem 2rem;
  font-size: small;
  cursor: pointer;
  color: #afafaf;
}

.closeIndividualForm {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.full {
  width: 100%;
}

.letterDispaly {
  font-size: smaller;
}
.letterDispaly a {
  color: blue;

  text-decoration: underline;
}

.editTeamTrack {
  z-index: 1;
}
.individualForm .title {
  font-weight: 600;
  font-size: 14px;
  width: 100% !important;
}
.allInfoConatier {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-height: 400px;
  width: 100%;
  overflow-y: auto;
}
.individualComponent {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  width: 90%;
  position: relative;
  width: 100%;
  border-radius: 10px;
}

.deadLineModalContainer strong {
  color: var(--linkColor);
}
.deadLineModalContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
}

.littleLine {
  width: 100px;
  background-color: var(--lightOrange);
  height: 5px;
}

.topOrangeLine {
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  background-color: var(--lightOrange);
  height: 9px;
}
.OrangeRoundedButon {
  background-color: var(--lightOrange);
  border: 0;
  outline: none;
  padding: 0.6rem 1rem;
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
  border-radius: 50px;
  color: white;
  font-weight: 500;
  cursor: pointer;
}
.grayText {
  color: #aaaaaa;
  font-weight: 600;
}
