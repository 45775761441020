.activityTime {
  font-size: smaller;
  font-weight: 700;
  color: #3f3f3f;
}
.activityAction {
  font-size: smaller;
  margin-left: 2rem;
}

.activityContainer {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  margin-bottom: 1.5rem;
}

.activityContainer li.activityTime {
  list-style: none;
}
.activityContainer li.activityTime::before {
  content: "·";
  font-size: 100px;
  vertical-align: top;
  line-height: 0px;
  /* margin-left: -0.8rem; */
  color: rgb(156, 156, 156);
}
