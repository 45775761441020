.progressVisualFull {
    display: flex;
    position: relative;
    height: 2.5rem;
    margin: 20px 0;
    width: 100%;
    border-radius: 40px;
  }
  
  .progressVisualPart {
    transition: width 2s;
    position: relative;
  }
.progressVisualPart:first-of-type {
    transition: width 2s;
    border-top-left-radius: 21px;
    border-bottom-left-radius: 21px;
    position: relative;
}

.progressVisualPart:nth-of-type(2) {
    transition: width 2s;
    border-top-right-radius: 21px;
    border-bottom-right-radius: 21px;
}
  
.ideaSubmitted{
    position: absolute;
    bottom: -25px;
    right: 0;
}
  
  