.main {
  display: flex;
  width: 100%;
  background-color: #f6fbfe !important;
}
.sideBarConatiner {
  width: 275px;
}
.mainContent {
  min-height: 97vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 2rem;
  padding-top: 90px;
  gap: 1.4rem;
  width: 100%;
}
.createAdminForm {
  background-color: white;
  padding: 1.5rem;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2rem;
}

.createForm {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
}
.inputGroup {
  position: relative;
  width: 100%;
  max-width: 520px;
}

.inputGroup label {
  position: absolute;
  top: -10px;
  left: 10px;
  background-color: white;
  font-size: smaller;
  color: var(--input-lable-color);
  padding: 0 5px;
}

.inputGroup input {
  width: 100%;
  max-width: 520px;
  border: 1.5px solid var(--gray-border-color);
  background-color: transparent;
  padding: 13px 1rem;
  border-radius: 6px;
}
.inputGroup input:focus {
  outline: 0;
}

.buttonContainer {
  width: 100%;
  display: flex;
  gap: 1rem;
}

.createButton {
  background-color: var(--lightOrange);
  color: white;
  padding: 0.6rem 1rem;
  border-radius: 5px;
  border: 0;
  outline: 0;
  cursor: pointer;
}
.cancelButton {
  color: var(--select-input-placeholder);
  padding: 0.6rem 1rem;
  border-radius: 5px;
  border: 2px solid var(--select-input-placeholder);
  outline: 0;
  background-color: white;
  cursor: pointer;
}
.graphsContainer {
  width: 95%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.title {
  width: 100%;
}

.statCardsConatiner {
  display: flex;
  gap: 2rem;
}

.statsCard {
  background-color: white;
  border-radius: 10px;
  padding: 1rem;
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.statsIcon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: linear-gradient(270deg, #7435ff 0%, #306bff 99.39%, #306bff 100%);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.statArray {
  display: flex;
}

.statInfo {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.statInfo > p {
  padding: 0 1rem;
}

.statInfo p {
  color: #a1aac8;
  font-size: small;
}

.statItem {
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  align-items: flex-start;
  padding: 0 1rem;
}

.statArray .statItem:first-child {
  border-right: 1px solid #aaa;
}

.statItem .number {
  color: #2b3674;
  font-weight: 600;
}

.statRow {
  display: flex;
  gap: 2rem;
  max-height: 250px;
  height: fit-content;
}
.statRow.last {
  max-height: 300px;
}

.skinnyCard {
  max-width: 370px;
}

.numericStats {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  width: 95%;
  height: 100%;
  justify-content: space-between;
}

.statSelect {
  border: 0 !important;
  box-shadow: 0px 1.68421px 20.21052px 0px rgba(112, 144, 176, 0.12);
  padding: 0.3rem !important;
  width: 150px !important;
}

.numericStats > div {
  border-radius: 12.632px;
  background: #fff;
  box-shadow: 0px 15.1579px 33.68421px 0px rgba(112, 144, 176, 0.12);
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  padding: 1rem;
  height: 100%;
}
.stateSpoc{
  min-height: 480px !important;
}
.stateMapRow{
  max-height: 100% !important;
}

.graphAndStatContainer {
  box-shadow: none !important;
  flex-wrap: nowrap !important;
  background-color: transparent !important;
  min-height: 190px;
}

.individualStat {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  padding-left: 0.8rem;
  min-width: 40%;
}

.statHeading {
  font-size: 9px;
  color: #a3aed0;
  position: relative;
}
.statHeading::before {
  content: "";
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: rgba(21, 73, 203, 1);
  position: absolute;
  left: -18px;
  top: 2.6px;
}
.statHeading.two::before {
  background-color: rgba(49, 104, 242, 1) !important;
}
.statHeading.custom.three::before {
  background-color: #5f9d80 !important;
}
.statHeading.three::before {
  background-color: rgba(108, 147, 245, 1) !important;
}
.statHeading.four::before {
  background-color: rgba(165, 191, 255, 1) !important;
}
.statHeading.custom.four::before {
  background-color: #17643f !important;
}
.statCount {
  font-size: 22px;
  font-weight: 600;
  padding-left: 1rem;
  color: rgba(21, 73, 203, 1);
}

.statCount.two {
  color: rgba(49, 104, 242, 1) !important;
}
.statCount.three {
  color: rgba(108, 147, 245, 1) !important;
}
.statCount.custom.three {
  color: #5f9d80 !important;
}
.statCount.four {
  color: rgba(165, 191, 255, 1) !important;
}
.statCount.custom.four {
  color: #17643f !important;
}

.graphContiner {
  width: 45%;
  height: 100%;
  position: relative;
}
.graphContiner svg{
  position: relative;
}
.donutGraphTitle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.halfScreenContainer {
  display: flex;
  gap: 2rem;
  width: 100%;
}

.barChartContainer {
  height: 100%;
  min-height: 190px;
}

.verticalStats {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 50%;
  justify-content: space-around;
}

.verticalStatContiner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  vertical-align: middle;
}

.verticalStatTitle {
  font-size: small;
  color: #2b3674;
  font-weight: 600;
}

.numberAndProgressContainer {
  display: flex;
  gap: 0.6rem;
  font-size: smaller;
  color: #aaa;
  align-items: center;
}

.donutLegendContainer {
  display: flex !important;
  gap: 2rem !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 0 !important;
  box-shadow: none !important;
  height: fit-content !important;
}

.donutLegendContainer div {
  font-size: small;
  color: #2b3674;
  font-weight: 600;
  position: relative;
}
.legendTitle::before {
  content: "";
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: rgba(21, 73, 203, 1);
  position: absolute;
  left: -18px;
  top: 2.6px;
}

.legendTitle.two::before {
  background-color: rgba(49, 104, 242, 1) !important;
}
.legendTitle.three::before {
  background-color: rgba(108, 147, 245, 1) !important;
}
.legendTitle.four::before {
  background-color: rgba(165, 191, 255, 1) !important;
}
.donutGraphTitle .title {
  color: #aaa;
  font-weight: 600;
}
.donutGraphTitle {
  font-size: small;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}

.totalDisplay {
  font-size: large;
  color: #1b2559;
  font-weight: 600;
}
.numericStats h4 {
  font-size: 14px;
}

.spocReqDis{
  width: 49% !important;
}