@tailwind base;
@tailwind components;
@tailwind utilities;

@import './editor.css';
@font-face {
  font-family: "Montserrat";
  src: url("https://saxenaprachi.github.io/publicAssets/fonts/Montserrat/Montserrat-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "MontserratBold";
  src: url("https://saxenaprachi.github.io/publicAssets/fonts/Montserrat/Montserrat-Bold.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

* {
  font-family: "Montserrat", sans-serif;
  box-sizing: border-box;
  padding: 0;
}
:root {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color-scheme: light dark;
  color: #444;
  background: #fafafa;
  --backgroundLightGray: #f4f4f4;
  --whitecolor: #fff;
  --black-color: #000;
  --dark-orange: #f75700;
  --green: #078e31;
  --light-green: #4bae4f;
  --lightOrange: #f69e45;
  --sih_blue: #1c1777;
  --gray-border-color: #d9d9d9;
  --input-lable-color: #4d4d4d;
  --input-placeholder: #808080;
  --select-input-placeholder: #cccccc;
  --blue-background: #f6fbfe;
  --linkColor: #4e7aed;
  --redColor: #ef0000;
  --noticeTextColor: #f7fbff;
  --noticeTextBorder: #bedfff;
  --toggle-color: #3f3f3f;
  --adminSidebarText: #545454;
  --adminSidebarIcon: #aaaaaa;
  --spocProgressbarColor1: #f0f0f0;
  --spocProgressbarColor2: #3752a9;
  --spocProgressbarColor3: #8e88ff;
  --extratGreen: #02581d;
  --statusBlue:#419eff;
}

#overlay {
  width: 100vw;
  background-color: transparent;
  position: fixed;
  height: 100vh;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  background: var(--whitecolor);
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  color: black;
}
input {
  background-color: transparent !important;
}
textarea {
  background-color: transparent !important;
}
select {
  background-color: transparent !important;
}

.textBlue {
  color: var(--sih_blue) !important;
}
.textLightBlue {
  color: hsl(211, 100%, 63%) !important;
}

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  /* font-size: 1rem; */
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.greenBtn {
  background: var(--green);
  color: var(--whitecolor);
  width: 40%;
  margin: auto;
}
.orangeBtn {
  background: var(--lightOrange);
  border: 2px solid var(--lightOrange);
  color: var(--whitecolor);
}
.secondaryBtn {
  border: 2px solid #afafaf;
  color: #afafaf;
}
.outlineBtnOrange {
  border: 2px solid var(--lightOrange);
  color: var(--lightOrange);
}

.fontWeight500 {
  font-weight: 500;
}
.fontWeight600 {
  font-weight: 600;
}
.fontWeight700 {
  font-weight: 700;
}
.textOrange {
  color: var(--dark-orange) !important;
}
.textLightOrange {
  color: var(--lightOrange);
}
.textRed {
  color: #ef0000;
}
.textLightBlue {
  color: var(--linkColor);
}

.boxShadowLight {
  box-shadow: 6px 1px 80px rgba(0, 0, 0, 0.08);
}

.search {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(145, 158, 171, 0.08);
  border-radius: 6px;
  /* gap: 0.5rem; */
}

.search input {
  border-radius: 6.67px;
}
.searchIcon {
  cursor: pointer;
  width: 100%;
  height: 100%;
  margin: 10px;
  max-width: 15px;
}
.searchIcon svg {
  color: #919eab;
}

.search input {
  background-color: transparent;
  border: 0;
  outline: none;
  padding: 10px;
  width: 100%;
}

.pagination button {
  background-color: white;
  border: 0;
  margin: 0 0.3rem;
  outline: none;
  cursor: pointer;
}

.clickable {
  color: blue;
  text-decoration: underline !important;
  cursor: pointer;
}
.filterContainer {
  border-radius: 4px;
  border: 2px solid #838383;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}
.filter {
  position: absolute;
  background-color: white;
  border-radius: 5px;
  top: 2.3rem;
  right: 0.5rem;
  padding: 10px;
  filter: drop-shadow(0px 0px 26px rgba(0, 0, 0, 0.15));
  font-size: 14px;
  color: #838383;
  z-index: 11;
}

.filter div {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #83838360;
  white-space: nowrap;
  padding: 0.4rem;
}

.filter div:last-child {
  border-bottom: 0;
}

.filter div p {
  display: none;
}

.filter div:hover p {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  padding-top: 0.3rem;
}
.filter div:hover p label {
  display: flex;
  margin-top: 0.4rem;
  height: 100%;
}
.uppercase {
  text-transform: uppercase;
}
.log {
  height: 85%;
  border: 0.5px solid rgb(156, 156, 156);
  position: absolute;
  top: 1.2rem;
  bottom: 0;
  width: 0px;
  margin-left: 12px;
}

.pagination {
  display: flex;
  align-items: center;
}
.pagination button {
  margin: 0 0.7rem !important;
  font-weight: 700;
}
.pagination button svg {
  font-size: 1.4rem;
}
.pagination button svg g path:nth-child(2) {
  fill: #767676;
}
.text-white {
  color: white;
}

.redStarFields {
  width: 100%;
  color: red;
  text-align: left;
  font-size: 0.75rem;
  font-weight: 500 !important;
}
.my-neg {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
}
.my-05 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.mb-1 {
  margin-bottom: 1rem;
}

.form_control {
  display: block;
  width: 100%;
  height: 54px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: var(--input-placeholder);
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 8px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
}

.form_control option {
  color: var(--input-placeholder);
}
.d-flex {
  display: flex;
}
.align-center {
  align-items: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-center {
  justify-content: center;
}

.ql-container.ql-snow {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.ql-toolbar.ql-snow {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.ql-editor {
  min-height: 18rem;
}
.mt-1 {
  margin-top: 1rem;
}
.text-center {
  text-align: center;
}
.text_Blue {
  color: hsl(211, 100%, 63%) !important;
  font-size: small !important;
  font-weight: 500;
}
.w-100 {
  width: 100%;
}
.ql-editor {
  height: 200px !important;
}
.fixedTicker {
  position: fixed;
  left: 0;
  right: 0;
  top: 3.6rem;
  opacity: 100% !important;
  z-index: 2;
}
.switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 45px;
  height: 19px;
  background: grey;
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
}

.switch-label .switch-button {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 15px;
  height: 15px;
  border-radius: 45px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.switch-checkbox:checked + .switch-label .switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.switch-label:active .switch-button {
  width: 30px;
}
.relative {
  position: relative;
}
.btnWhite{
  background: var(--whitecolor) !important;
  color: var(--lightOrange) !important;
}
