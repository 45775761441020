.footer {
  width: 100%;
  left: 0;
  bottom: 0;
  right: 0;
  background: var(--whitecolor);
  z-index: 50;
  padding: 1.8rem 2rem;
  margin: 0;
  display: flex;
  justify-content: space-between;
  z-index: 10;
  position: relative;
}

.footerContent {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.footerHeading {
  color: var(--sih_blue);
  font-weight: 700;
  text-decoration: underline;
  font-size: large;
  text-transform: uppercase;
}
.socialContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  color: var(--sih_blue);
  font-size: large;
}
.footerLeft {
  width: 50%;
  display: flex;
  justify-content: space-between;
}
.copyright {
  font-size: small;
  color: var(--input-lable-color);
  display: flex;
  align-items: center;
}
.copyrightReal {
  font-size: small;
  color: var(--input-lable-color);
  display: flex;
  align-items: center;
  position: absolute;
  left: 2rem;
  bottom: 1rem;
}
.supportEmails{
  color: var(--lightOrange);
  font-weight: 600;
}
.comma{
  margin-right: 5px;
}
@media (max-width: 845px) {
  .copyright {
    font-size: x-small;
  }
  .copyrightReal {
    font-size: x-small;
  }
}
@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    gap: 1rem;
  }
  .lgIcon {
    display: none;
  }
  .footerLeft {
    width: 100%;
  }
}
