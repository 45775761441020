.sideBar {
  /* padding: 5rem 0rem; */
  z-index: 90;
  background-color: white;
}

/* Sidebar.module.css */
.sidebar {
  /* width: 275px; */
  height: 100%;
  background-color: var(--whitecolor);
  /* position: fixed; */
  top: 0;
  left: 0px;
  bottom: 0;
  transition: left 0.3s ease-in-out;
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 90;
}

.sidebar ul {
  list-style: none;
  /* padding: 0 1.5rem; */
  padding-bottom: 14.5rem;
  text-align: center;
}

.sidebar ul li {
  padding: 10px 1.3rem;
  display: flex;
  align-items: center;
  color: var(--adminSidebarText);
  width: 235px;
}
.sidebar ul li a {
  text-decoration: none;
  color: var(--adminSidebarText);
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.sidebar ul li div {
  display: inline;
  color: var(--adminSidebarText);
  font-size: small;
}
.sidebar ul li svg {
  display: inline;
  font-size: 2rem;
}
.sidebar ul li svg path {
  color: var(--adminSidebarIcon);
}
.poweredByH2sLogo {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: small;
  font-weight: 500;
}
.poweredByH2sLogo img {
  padding-left: 7px;
}

/* Media query for responsive behavior */
@media screen and (max-width: 768px) {
  .sidebar {
    left: 0;
  }
}
.sidebar ul li.active {
  background-color: var(--lightOrange);
}
.sidebar ul li.active div {
  color: white;
}
.sidebar ul li.active svg path {
  color: white;
}
