.main {
  display: flex;
  width: 100%;
}
.sideBarConatiner {
  width: 275px;
}
.mainContent {
  height: 97vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 2rem;
  padding-top: 90px;
  gap: 1.4rem;
  width: 100%;
}
.createAdminForm {
  background-color: white;
  padding: 1.5rem;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2rem;
}

.createForm {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
}
.inputGroup {
  position: relative;
  width: 100%;
  max-width: 520px;
}

.inputGroup label {
  position: absolute;
  top: -10px;
  left: 10px;
  background-color: white;
  font-size: smaller;
  color: var(--input-lable-color);
  padding: 0 5px;
}

.inputGroup input {
  width: 100%;
  max-width: 520px;
  border: 1.5px solid var(--gray-border-color);
  background-color: transparent;
  padding: 13px 1rem;
  border-radius: 6px;
}
.inputGroup input:focus {
  outline: 0;
}

.buttonContainer {
  width: 100%;
  display: flex;
  gap: 1rem;
}

.createButton {
  background-color: var(--lightOrange);
  color: white;
  padding: 0.6rem 1rem;
  border-radius: 5px;
  border: 0;
  outline: 0;
  cursor: pointer;
}
.cancelButton {
  color: var(--select-input-placeholder);
  padding: 0.6rem 1rem;
  border-radius: 5px;
  border: 2px solid var(--select-input-placeholder);
  outline: 0;
  background-color: white;
  cursor: pointer;
}

.paginationContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: smaller;
}
.paginationContainer select {
  padding: 0.1rem;
}
.tableHeadConatiner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  width: 100%;
}
.searchConatiner {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  gap: 1rem;
}

.statConatiner {
  display: flex;
  padding: 5px;
  border-bottom: 1px solid #d9d9d9;
  width: fit-content;
  font-size: x-small;
}
@media (max-width: 600px) {
  .statConatiner {
    font-size: x-small;
  }
}
.stat {
  margin: 6px;
  padding: 0 6px;
  display: flex;
  border-right: 1px solid #d9d9d9;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
}
.stat:last-child {
  border-right: none;
}
.statNumber {
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(246, 158, 69, 0.2);
  border-radius: 3px;
  color: var(--dark-orange);
}
