.individualForm {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  width: 90%;
  position: relative;
}

.formGroup {
  display: flex;
  flex-direction: column;
  gap: 5px;
  /* min-width: 350px; */
  width: 48%;
  position: relative;
}
@media (max-width: 768px) {
  .formGroup {
    width: 100%;
  }
}

.formInput,
select {
  border-radius: 4.545px;
  border: 0.909px solid #d9d9d9;
  outline: none;
  padding: 0.6rem 1rem;
}

.formGroup label {
  background-color: white;
  padding: 6px;
  font-size: x-small;
  position: absolute;
  top: -14px;
  left: 8px;
}
.formGroup label span {
  color: red;
}

.memberForm {
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  padding: 1rem;
}

.overallFormHeading {
  font-size: small;
  font-weight: 600;
  width: 100%;
}

.closeIndividualForm {
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}

.removePadding {
  padding: 0 !important;
  width: 100% !important;
}
