.table {
  border-radius: 10px;
  border-collapse: collapse;
  width: 100%;
}
.table td {
  margin: 0;
  border: 0;
  padding: 0.5rem 1rem;
  overflow: hidden;
  white-space: normal; /* Allow text to wrap within the cell */
  word-wrap: break-word; /* Wrap long words onto the next line */
  max-width: 200px;
}
.table thead tr {
  background-color: #ebebeb;
  border-radius: 10px 10px 0 0;
}

.table thead tr td {
  text-transform: uppercase;
  font-size: small;
  /* text-align: center; */
}
.table thead tr td:first-child {
  border-radius: 10px 0 0 0;
}
.table thead tr td:last-child {
  border-radius: 0 10px 0 0;
}

.table tbody tr {
  background-color: white;
  border-radius: 0 0 10px 10px;
}

.table tbody tr:last-child td:first-child {
  border-radius: 0 0 0 10px;
}

.table tbody tr:last-child td:last-child {
  border-radius: 0 0 10px 0;
}

.tableContainer {
  width: 100%;
  overflow-x: auto;
}

.table tr td {
  font-size: small;
  overflow: hidden;
  white-space: normal; /* Allow text to wrap within the cell */
  word-wrap: break-word; /* Wrap long words onto the next line */
  max-width: 200px;
}

.textLink {
  color: #419eff;
  text-decoration: underline;
  cursor: pointer;
}

.dangerButton {
  background-color: #ef0000;
  color: white;
  text-align: center;
  cursor: pointer;
  border: 0;
  outline: none;
  padding: 0.3rem 1rem;
  border-radius: 6px;
  width: 100%;
  font-size: x-small;
  text-transform: uppercase;
}
.reviewButton {
  background-color: #f2b042;
  color: white;
  text-align: center;
  cursor: pointer;
  border: 0;
  outline: none;
  padding: 0.3rem 1rem;
  border-radius: 6px;
  width: 100%;
  font-size: x-small;
  text-transform: uppercase;
  display: block;
  text-wrap: nowrap;
}
.verifiedButton {
  background-color: #24c356;
  color: white;
  text-align: center;
  cursor: pointer;
  outline: none;
  border-radius: 6px;
  width: 100%;
  padding: 0.3rem 1rem;
  border-radius: 6px;
  width: 100%;
  font-size: x-small;
  border: 0;
  text-transform: uppercase;
}
.nominatedButton {
  background-color: #02581d;
  color: white;
  text-align: center;
  cursor: pointer;
  outline: none;
  border-radius: 6px;
  width: 100%;
  padding: 0.3rem 1rem;
  border-radius: 6px;
  width: 100%;
  font-size: x-small;
  border: 0;
  text-transform: uppercase;
}
.grayButton {
  background-color: #419eff;
  color: white;
  text-align: center;
  cursor: pointer;
  border: 0;
  outline: none;
  padding: 0.3rem 1rem;
  border-radius: 6px;
  width: 100%;
  font-size: x-small;
  text-transform: uppercase;
}
.yellowButton {
  background-color: #f2b042;
  color: white;
  text-align: center;
  cursor: pointer;
  border: 0;
  outline: none;
  padding: 0.3rem 1rem;
  border-radius: 6px;
  width: 100%;
  font-size: x-small;
  text-transform: uppercase;
}

.disabled {
  cursor: default !important;
}

.statusConatiner {
  color: white;
  background-color: #f2b042;
  padding: 0.2rem 0.5rem;
  border-radius: 5px;
  width: 100%;
  font-size: x-small;
  text-transform: uppercase;
  text-align: center;
}
.deleteButton {
  color: white;
  background-color: #ef0000;
  padding: 0.2rem 0.5rem;
  border-radius: 5px;
  width: fit-content;
  cursor: pointer;
}

.statusConatiner.green {
  background-color: #24c356;
}

.statusConatiner.blue {
  background-color: #419eff;
}
.statusConatiner.darkgreen {
  background-color: #02581d;
}
.centerAlign {
  text-align: center;
}
.actionsContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.actionsContainer div {
  cursor: pointer;
  font-size: large !important;
  margin-bottom: -5px;
}

.submissionsList {
  overflow: hidden;
  text-overflow: ellipsis !important; /* This will show an ellipsis (...) when content overflows */
  white-space: nowrap !important; /* Prevents content from wrapping to the next line */
  max-width: 200px;
}

.actionsContainer .disabled svg path {
  color: gray !important;
  fill: gray !important;
}
.actionsContainer .disabled {
  cursor: not-allowed !important;
}
 