.rocketRight {
  position: absolute;
  right: 0;
  top: 10rem;
}
.pageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 2rem 0;
}
.formConatiner {
  position: relative;
  width: 80vw;
  background-color: white;
  box-shadow: 4px 0px 27px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 3rem;
}

.Heading {
  font-size: large;
  font-weight: 600;
}

.description {
  font-size: smaller;
  color: #419eff;
  font-weight: 400;
  margin-top: -0.6rem;
}
.close {
  position: absolute;
  right: 2rem;
  top: 2rem;
}

.tableContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  gap: 2rem;
  overflow-x: auto;
}

.tableContainer .header {
  display: flex;
  width: fit-content;
  background-color: #ebebeb;
  padding: 0.6rem 0rem;
  border-radius: 10px;
  justify-content: center;
}
.tableContainer .header div {
  font-size: 13px;
  font-weight: 600;
  /* width: 100%; */
  text-align: center;
  width: 220px;
  background-color: #ebebeb;
}
.nominationItem {
  display: flex;
  /* padding: 0 1rem 0 0; */
  width: fit-content;
  border: 1px solid #f69e45;
  border-radius: 10px;
  font-size: 13px;
  /* justify-content: center; */
}
.ideaContainer {
  display: flex;
  padding: 0.6rem 0;
  justify-content: center;
}
.ideaContainer div {
  width: 100%;
  text-align: center;
}

.teamNameConatier {
  /* width: 25%; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px 0 0 10px;
  background-color: #fdecda50;
  text-align: center;
  font-weight: 600;
  width: 220px;
}
.ideaContainer > div {
  width: 220px;
}

.ideaContainer div:nth-child(1) div:nth-child(1) {
  width: 20px;
  margin-left: 10px;
}
.ideaContainer div:nth-child(1) {
  display: flex;
}

.paginationContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}
.dangerTheme {
  color: #f15958;
  background-color: #f34d5d20;
  border-color: #f15958;
  display: flex;
  align-items: center;
  padding: 0.8rem 3rem;
}
.dangerTheme span {
  color: #f15958;
  margin-left: 1rem;
}
.noticeText {
  border: none;
  display: flex;
  align-items: center;
  padding: 0.8rem 3rem;
  /* position: absolute; */
  left: 0;
  right: 0;
  margin-top: 5.5rem;
}
.turncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
  display: block;
}

.trackName {
  justify-content: space-between !important;
}
/* CSS */
.modal {
  display: block;
  position: fixed;
  z-index: 33;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}
.modal iframe {
  height: 25rem;
}
.modal_content {
  background-color: #fefefe;
  margin: 10% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 600px;
  position: relative;
  height: auto;
}

.modal_content .close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  margin-top: -1rem;
}

.modal_content .close:hover,
.modal_content .close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.headerOfModal {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
  align-items: center;
}
@media (max-width: 600px) {
  .modal_content {
    margin: 18% auto;
  }
}
