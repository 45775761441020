.main {
  display: flex;
  width: 100%;
}
.sideBarConatiner {
  width: 275px;
}
.mainContent {
  height: 97vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
