.dashboardTopDiv {
  background: url("https://saxenaprachi.github.io/publicAssets/images/dashboard-top-backgraund.webp");
  background-size: cover;
  background-position: left;
  background-repeat: no-repeat;
  padding-top: 1rem;
  padding-bottom: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  gap: 1rem;
  width: 100%;
}
.fontAndWeight {
  font-weight: 600;
  /* font-size: 16px; */
  color: var(--black-color);
}
.rocketRight {
  position: absolute;
  right: 0;
  top: 10rem;
}
.breadCrum {
  color: var(--input-lable-color);
  padding: 0rem 3rem;
  padding-bottom: 1.6rem;
  font-weight: 500;
}
.breadCrum a {
  color: var(--input-lable-color);
  /* font-size: 15px; */
}
.col1 {
  display: flex;
}

.locationIcon svg {
  color: var(--black-color);
  margin-bottom: -5px;
  margin-right: 6px;
  font-size: 1rem;
}
.allTabsContentOuter {
  padding: 0 2rem;
}
.allTabsContentOuter h4 {
  padding-top: 1.3rem;
}
.horizontalLine {
  background-color: #d9d9d9;
  height: 1px;
  border: none;
  margin-top: 4px;
}
.tabs Link {
  border: none;
  background: transparent;
  padding: 1rem 0rem;
  text-transform: uppercase;
  font-weight: 600;
  margin-right: 2rem;
}
.tabsOuterBox {
  background: var(--whitecolor);
  border-radius: 10px;
  box-shadow: 6px 1px 80px rgba(0, 0, 0, 0.08);
  padding: 1.5rem;
  margin-top: -7.5rem;
}
.tabs {
  padding-bottom: 0.6rem !important;
  padding: 0 2rem;
}
Link.active {
  border-bottom: 3px solid var(--lightOrange) !important;
  color: var(--lightOrange);
}

.spocDashboard {
  margin-top: 4.2rem;
  font-size: small !important;
}
.threeCols {
  display: flex;
  width: 100%;
  color: var(--input-lable-color);
  padding: 0rem 6rem;
  /* padding-bottom: 2.8rem; */
}

/* Define the style for each column */

.col1 {
  width: 46.3%;
}
.col2 {
  width: 33.3%;
}
.column .leftImage {
  width: 31%;
  margin-right: 1.6rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0.4rem;
  min-width: 180px;
  min-height: 170px;
}
.col1 img {
  width: 100%;
  border: 2px solid var(--lightOrange);
}
.column h5 {
  color: #bdbdbd;
  /* font-size: 15px; */
  padding-bottom: 13px;
  font-weight: 600;
}
.column p {
  padding-bottom: 13px;
}

.col3 {
  position: relative;
  width: 25%;
}
.poweredByH2sLogo {
  text-align: right; /* Align content to the right */
  margin-top: auto; /* Push content to the bottom */
  display: flex;
  align-items: center;
  text-align: right;
  justify-content: flex-end;
  /* padding-top: 1rem; */
  /* font-size: 15px; */
  font-weight: 500;
  position: absolute;
  bottom: 10px;
}
.poweredByH2sLogo img {
  padding-left: 7px;
}

.noticeText {
  border: none;
  display: flex;
  align-items: center;
  padding: 0.8rem 3rem;
}

.successTheme {
  color: #30ad38;
  background-color: #dfefe8;
  border-color: #30ad38;
  display: flex;
  align-items: center;
  padding: 0.8rem 3rem;
}

.dangerTheme {
  color: #f15958;
  background-color: #efe4e4;
  border-color: #f15958;
  display: flex;
  align-items: center;
  padding: 0.8rem 3rem;
}

.infoTheme {
  color: #0188f4;
  background-color: #f2f8ff;
  border-color: #0188f4;
  display: flex;
  align-items: center;
  padding: 0.8rem 3rem;
}

.warningTheme {
  color: #e6bb00;
  background-color: #f5ecda;
  border-color: #e6bb00;
  display: flex;
  align-items: center;
  padding: 0.8rem 3rem;
}

.noticeText svg {
  font-size: 1.9rem !important;
  padding-right: 5px;
}

.spocDasboardContent {
  padding: 3rem 5rem;
  margin-top: -3rem;
  background: var(--backgroundLightGray);
}
.verticleLine {
  margin-right: 3rem;
  border-left: 1px solid #d9d9d9;
}

/* Timeline Container */
.timeline {
  background: var(--primary-color);
  margin: 20px auto;
  padding: 20px;
}

/* Outer Layer with the timeline border */
.outer {
  border-left: 2px dashed #838383;
}

/* Card container */
.card {
  position: relative;
  margin: 0 0 20px 20px;
  padding: 10px;
  padding-top: 0px;
  background: transparent;
  color: var(--input-lable-color);
  border-radius: 8px;
  max-width: 100%;
}
.card ul {
  padding-left: 1.6rem;
}
.card ul li {
  line-height: 1.5rem;
}
.card ul li::marker {
  color: var(--sih_blue);
  /* font-size: 28px; */
}

.card ol {
  padding-left: 1.2rem;
  padding-top: 0.5rem;
}
.themes {
  display: flex;
  gap: 1.6rem;
  flex-wrap: wrap;
  margin-top: 1rem;
  /* justify-content: space-between; */
}

.themesCard {
  padding: 4rem 3.3rem;
  background: var(--whitecolor);
  text-align: center;
  box-shadow: 6px 1px 80px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}
/* Information about the timeline */
.info {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Title of the card */
.title {
  color: var(--input-placeholder);
  position: relative;
  /* font-size: 1rem; */
}

/* Timeline dot  */
.title::before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  background: #d9d9d9;
  border-radius: 999px;
  left: -43.9px;
  border: 5px solid #838383;
}
@media (max-width: 1460px) {
  .verticleLine {
    margin: 0rem 2rem;
  }
}

@media (max-width: 1400px) {
  .threeCols {
    padding: 0rem 3rem;
    padding-bottom: 3rem;
  }
}

.hrCol1Mobile {
  display: none;
}
@media (max-width: 1200px) {
  .hrCol1Mobile {
    display: block;
    background-color: #d9d9d9;
    height: 2px;
    border: none;
    margin-bottom: 22px;
  }
  .verticleLine {
    display: none;
  }
  .breadCrum {
    padding: 0rem 1rem;
    padding-bottom: 1.6rem;
  }
  .threeCols {
    flex-direction: column;
  }
  .column {
    width: 100%;
    margin-bottom: 1.5rem;
  }
  .poweredByH2sLogo {
    position: relative;
    padding-top: 1rem;
  }
  .col1 {
    display: block;
  }
  .rocketRight {
    z-index: 1;
  }
  .col1 img {
    width: 65%;
    margin-bottom: 0.9rem;
  }
}

@media (max-width: 768px) {
  .threeCols {
    padding-bottom: 0;
  }
  .col3 {
    margin-bottom: 0rem;
  }
  .column .leftImage {
    width: 45%;
  }
  .col1 img {
    width: 70%;
    margin-bottom: 0.9rem;
  }
  .themes {
    gap: 1rem;
  }
  .rocketRight {
    top: 13rem;
    width: 120px;
    z-index: 1;
  }
}
@media (max-width: 767px) {
  .tabsOuterBox {
    padding: 1rem;
  }
  .spocDasboardContent {
    padding: 3rem 1.5rem;
  }
  .allTabsContentOuter {
    padding: 0 0rem;
  }
  .noticeText {
    /* font-size: 0.9rem; */
    padding: 0.8rem 1rem;
  }
  .noticeText svg {
    font-size: 3.5rem;
  }
  .poweredByH2sLogo {
    justify-content: flex-start;
  }
}

@media (max-width: 578px) {
  .rocketRight {
    top: 14rem;
    width: 90px;
  }
  .themesCard {
    padding: 5.7rem 5.3rem;
  }
  .timeline {
    padding: 5px;
  }
  .card ul {
    padding-left: 1.1rem;
  }
  .tabs {
    padding: 0 1rem;
  }
}
@media (max-width: 420px) {
  .threeCols {
    padding: 0rem 1rem;
  }
  .poweredByH2sLogo {
    /* font-size: 0.9rem; */
    justify-content: flex-start;
  }
  .poweredByH2sLogo img {
    width: 75px;
  }
}
