.fontAndWeight {
  font-weight: 600;
  /* font-size: 16px; */
  color: var(--black-color);
}
.rocketRight {
  position: absolute;
  right: 0;
  top: 10rem;
}
.breadCrum {
  color: var(--input-lable-color);
  padding: 0rem 3rem;
  padding-bottom: 1.6rem;
  font-weight: 500;
}
.breadCrum a {
  color: var(--input-lable-color);
  /* font-size: 15px; */
}
.col1 {
  display: flex;
}

.locationIcon svg {
  color: var(--black-color);
  margin-bottom: -5px;
  margin-right: 6px;
  font-size: 1rem;
}
.allTabsContentOuter {
  padding: 0 2rem;
}
.allTabsContentOuter h4 {
  padding-top: 1.3rem;
}
.horizontalLine {
  background-color: #d9d9d9;
  height: 1px;
  border: none;
  margin-top: 4px;
}
.tabs a {
  border: none;
  background: transparent;
  padding: 1rem 0rem;
  text-transform: uppercase;
  font-weight: 600;
  margin-right: 2rem;
}
.tabsOuterBox {
  background: var(--whitecolor);
  border-radius: 10px;
  box-shadow: 6px 1px 80px rgba(0, 0, 0, 0.08);
  padding: 1.5rem;
  margin-top: -7.5rem;
}
.tabs {
  padding-bottom: 0.6rem !important;
  padding: 0 2rem;
  overflow-x: auto;
  width: 100%;
  display: flex;
}
.tabs a {
  white-space: nowrap;
}
a.active {
  border-bottom: 3px solid var(--lightOrange) !important;
  color: var(--lightOrange);
}

.spocDashboard {
  margin-top: 4.2rem;
  font-size: small !important;
}
.threeCols {
  display: flex;
  width: 100%;
  color: var(--input-lable-color);
  padding: 0rem 6rem;
  /* padding-bottom: 2.8rem; */
}

.col1 {
  width: 46.3%;
}
.col2 {
  width: 33.3%;
}
.column .leftImage {
  width: 31%;
  margin-right: 1.6rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0.4rem;
  min-width: 180px;
}
.col1 img {
  width: 100%;
  border: 2px solid #ce257e;
}
.column h5 {
  color: #bdbdbd;
  /* font-size: 15px; */
  padding-bottom: 13px;
  font-weight: 600;
}
.column p {
  padding-bottom: 13px;
}

.col3 {
  position: relative;
  width: 25%;
}
.poweredByH2sLogo {
  text-align: right; /* Align content to the right */
  margin-top: auto; /* Push content to the bottom */
  display: flex;
  align-items: center;
  text-align: right;
  justify-content: flex-end;
  padding-top: 1rem;
  /* font-size: 15px; */
  font-weight: 500;
  position: absolute;
  bottom: 10px;
}
.poweredByH2sLogo img {
  padding-left: 7px;
}

.noticeText {
  background: #e8f0f9;
  color: #0188f4;
  display: flex;
  align-items: center;
  padding: 0.8rem 3rem;
}
.noticeText svg {
  /* font-size: 1.9rem; */
  padding-right: 5px;
}

.spocDasboardContent {
  padding: 3rem 5rem;
  margin-top: -3rem;
  background: var(--backgroundLightGray);
}
.verticleLine {
  margin-right: 3rem;
  border-left: 1px solid #d9d9d9;
}

/* Timeline Container */
.timeline {
  background: var(--primary-color);
  margin: 20px auto;
  padding: 20px;
}

/* Outer Layer with the timeline border */
.outer {
  border-left: 2px dashed #838383;
}

/* Card container */
.card {
  position: relative;
  margin: 0 0 20px 20px;
  padding: 10px;
  padding-top: 0px;
  background: transparent;
  color: var(--input-lable-color);
  border-radius: 8px;
  max-width: 100%;
}
.card ul {
  padding-left: 1.6rem;
}
.card ul li {
  line-height: 1.5rem;
}
.card ul li::marker {
  color: var(--sih_blue);
  /* font-size: 28px; */
}

.card ol {
  padding-left: 1.2rem;
  padding-top: 0.5rem;
}
.themes {
  display: flex;
  gap: 1.6rem;
  flex-wrap: wrap;
  margin-top: 1rem;
  /* justify-content: space-between; */
}

.themesCard {
  padding: 4rem 3.3rem;
  background: var(--whitecolor);
  text-align: center;
  box-shadow: 6px 1px 80px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}
/* Information about the timeline */
.info {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Title of the card */
.title {
  color: var(--input-placeholder);
  position: relative;
  /* font-size: 1rem; */
}

/* Timeline dot  */
.title::before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  background: #d9d9d9;
  border-radius: 999px;
  left: -43.9px;
  border: 5px solid #838383;
}

@media (max-width: 1460px) {
  .verticleLine {
    margin: 0rem 2rem;
  }
}

@media (max-width: 1400px) {
  .threeCols {
    padding: 0rem 3rem;
    padding-bottom: 3rem;
  }
}

.hrCol1Mobile {
  display: none;
}
@media (max-width: 1200px) {
  .hrCol1Mobile {
    display: block;
    background-color: #d9d9d9;
    height: 2px;
    border: none;
    margin-bottom: 22px;
  }
  .verticleLine {
    display: none;
  }
  .breadCrum {
    padding: 0rem 1rem;
    padding-bottom: 1.6rem;
  }
  .threeCols {
    flex-direction: column;
  }
  .column {
    width: 100%;
    margin-bottom: 1.5rem;
  }
  .poweredByH2sLogo {
    position: relative;
    padding-top: 3.2rem;
  }
  .col1 {
    display: block;
  }
  .rocketRight {
    z-index: 1;
  }
  .col1 img {
    width: 65%;
    margin-bottom: 0.9rem;
  }
}

@media (max-width: 768px) {
  .threeCols {
    padding-bottom: 0;
  }
  .col3 {
    margin-bottom: 0rem;
  }
  .column .leftImage {
    width: 45%;
  }
  .col1 img {
    width: 70%;
    margin-bottom: 0.9rem;
  }
  .themes {
    gap: 1rem;
  }
  .rocketRight {
    top: 13rem;
    width: 120px;
    z-index: 1;
  }
}
@media (max-width: 767px) {
  .tabsOuterBox {
    padding: 1rem;
  }
  .spocDasboardContent {
    padding: 3rem 1.5rem;
  }
  .allTabsContentOuter {
    padding: 0 0rem;
  }
  .noticeText {
    /* font-size: 0.9rem; */
    padding: 0.8rem 1rem;
  }
  .poweredByH2sLogo {
    justify-content: flex-start;
  }
}

@media (max-width: 578px) {
  .rocketRight {
    top: 14rem;
    width: 90px;
  }
  .themesCard {
    padding: 5.7rem 5.3rem;
  }
  .timeline {
    padding: 5px;
  }
  .card ul {
    padding-left: 1.1rem;
  }
  .tabs {
    padding: 0 1rem;
  }
}
@media (max-width: 420px) {
  .threeCols {
    padding: 0rem 1rem;
  }

  .poweredByH2sLogo {
    /* font-size: 0.9rem; */
    justify-content: flex-start;
  }
  .poweredByH2sLogo img {
    width: 75px;
  }
}
.pageTitle {
  text-transform: uppercase;
  font-size: large;
}
.mainConatiner {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0;
}
.statConatiner {
  display: flex;
  padding: 5px;
  border-bottom: 1px solid #d9d9d9;
  width: fit-content;
}
@media (max-width: 600px) {
  .statConatiner {
    font-size: x-small;
  }
}
.stat {
  margin: 6px;
  padding: 0 6px;
  display: flex;
  border-right: 1px solid #d9d9d9;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
}
.stat:last-child {
  border-right: none;
}
.statNumber {
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(246, 158, 69, 0.2);
  border-radius: 3px;
  color: var(--dark-orange);
}
.tableHeadConatiner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}
.searchConatiner {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  gap: 1rem;
}
.tableTitle {
  font-size: large;
  font-weight: 600;
  color: var(--toggle-color);
}

.paginationContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.disabled {
  cursor: default !important;
  color: #838383 !important;
}

@media (max-width: 695px) {
  .tableTitle {
    font-size: medium;
  }
  .searchConatiner {
    max-width: 250px;
  }
  .searchConatiner .btn {
    font-size: xx-small !important;
  }
}

.nominateButtonContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 4rem;
  background-color: #fdf6ee;
  padding: 2rem;
  border-radius: 10px;
  gap: 1rem;
}
.nominateButtonContainer a {
  padding: 0.375rem 3.75rem !important;
}
.nominationText {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  width: 70%;
}

.nominationText p {
  font-size: 12px;
}

.nominationText h4 {
  font-size: large;
  font-weight: 600;
  margin: 0;
  padding: 0;
}

.nominationStats {
  border-radius: 17.359px;
  background: #fff;
  box-shadow: 0px 0px 47.37078px 0px rgba(0, 0, 0, 0.15);
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  width: 35%;
}

.graphConatiner {
  height: 9rem;
}

.nominationStats h4 {
  margin: 0;
  padding: 0;

  font-size: 16px;
}

.labelsConatiner {
  display: flex;
  gap: 2rem;
  justify-content: center;
  align-items: center;
}
.labelsConatiner h5 {
  position: relative;
}
.labelsConatiner h5::before {
  content: "";
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #f69e45;
  position: absolute;
  left: -18px;
  top: 2px;
}
.labelsConatiner h5:nth-child(2):before {
  background-color: #1c1777;
}
@media (max-width: 1024px) {
  .nominationText {
    width: 50%;
  }
  .nominationStats {
    width: 50%;
  }
}
@media (max-width: 640px) {
  .nominationText {
    width: 100%;
  }
  .nominationStats {
    width: 100%;
  }
  .nominateButtonContainer {
    flex-direction: column;
  }
}
/* CSS */
.modal {
  display: block;
  position: fixed;
  z-index: 33;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}
.modal iframe {
  height: 25rem;
}
.modal_content {
  background-color: #fefefe;
  margin: 10% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 600px;
  position: relative;
  height: auto;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.headerOfModal {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
  align-items: center;
}
@media (max-width: 600px) {
  .modal_content {
    margin: 18% auto;
  }
}

.referenceBox{
  padding: 1.5rem;
  background: #FDF6EE;
  border-radius: 10px;
  margin-top: 2rem;
  text-align: left;
  overflow-x: auto;
}
.referenceBox table{
  width: 100%;
  text-align: center;
}
.referenceBox table tbody tr:nth-child(3) td:first-child{
  border-bottom-left-radius: 10px;
}
.referenceBox table tbody tr:nth-child(3) td:last-child{
  border-bottom-right-radius: 10px;
}
.referenceBox table tbody tr{
  background: var(--whitecolor);
}
.referenceBox table thead tr{
  background: #419dff2c;
  border-top-right-radius: 10px;
}
.referenceBox table thead tr th{
  color: var(--input-placeholder);
}
.referenceBox table thead tr th:first-child{
  border-top-left-radius: 10px;
}
.referenceBox table thead tr th:last-child{
  border-top-right-radius: 10px;
}
.referenceBox table tr td{
  padding: 0.7rem;
}
.referenceBox table tr th{
  padding: 0.7rem;
}
.statusBtn{
  display: block;
  color: white;
  background-color: #f2b042;
  padding: 0.4rem 0.5rem;
  border-radius: 5px;
  width: 100%;
  font-size: x-small;
  text-transform: uppercase;
  text-align: center;
}
.orangeStatus{
  background: var(--lightOrange);
}
.extraGreenStatus{
  background: var(--extratGreen);
}
.blueStatus{
  background: var(--statusBlue);
}
.referenceHeading{
  font-size: large;
  padding: 1rem 0rem;
}
.referenceBox table svg {
  font-size: 1.5rem;
}
.referenceBox table svg path{
  color: var(--light-green);
}
.referenceBox table .crossIcon svg path{
  color: var(--redColor) !important;
}