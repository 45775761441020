.overlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #00000020;
    z-index: 20;
}
.cookieConsentMain{
    background: var(--lightOrange);
    padding: 1.5rem 2rem;
    display: flex;
    align-items: center;
    gap: 2rem;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 21;
    font-size: small;
}
.cookieConsentMain p{
    width: 75%;
    color: var(--whitecolor);
}
.cookieConsentMain svg {
    font-size: 2.5rem;
}
.cookieConsentMain svg path {
    fill: var(--whitecolor);
}
.learnMore{
    font-weight: 600;
    text-decoration: underline !important;
    color: var(--whitecolor);
    margin-left: 2.5px;
}
@media(max-width:1100px){
    .cookieConsentMain p {
        width: 70%;
    }
}
@media(max-width:1048px){
    .cookieConsentMain p {
        width: 60%;
    }
}
@media(max-width:769px){
    .cookieConsentMain p {
        width: 50%;
    }
}
@media(max-width:700px){
    .cookieConsentMain p {
        width: 80%;
    }
    .cookieConsentMain .acceptBtn {
        width: 100% !important;
    }
    .cookieConsentMain{
        flex-wrap: wrap;
    }
}
@media(max-width:400px){
    .cookieConsentMain p {
        width: 100%;
    }
}