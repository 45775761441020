html {
  background: var(--backgroundLightGray);
}
.redColor {
  color: var(--redColor);
}
.linkColor {
  color: var(--linkColor);
}
.mb2 {
  margin-bottom: 2.2rem;
}
.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.greenBtn {
  background: var(--green);
  color: var(--whitecolor);
  width: 40%;
  margin: auto;
}

.rightSideNotice {
  padding: 1.1rem;
  font-size: 0.9rem;
  font-weight: 500;
}
.rightSideNotice h5 {
  font-size: 1.5rem;
}
.rightSideNotice p {
  padding-top: 0.7rem;
  line-height: 1.4rem;
}
.rightSideNotice p span {
  font-weight: 700;
}

.schoolRegistrationDiv {
  position: relative;
  background: var(--whitecolor);
  margin-top: 6.5rem !important;
  margin-bottom: 3.5rem !important;
  margin: auto;
  width: 80%;
  padding: 2rem 2.5rem;
  border-radius: 1rem;
  box-shadow: 6px 1px 80px rgba(0, 0, 0, 0.08);
}
.rocketRight {
  position: absolute;
  right: 0;
  top: 8rem;
}

.links {
  position: sticky;
  width: 25%;
  float: left;
  text-align: left;
  background: var(--noticeTextColor);
  border: 1px solid var(--noticeTextBorder);
  border-radius: 7px;
  top: 140px;
}
.social_media {
  width: 75%;
  float: right;
  text-align: left;
  padding: 0rem 2.5rem;
}
.social_media h1 {
  color: var(--sih_blue);
  font-size: 1.85rem;
  padding-bottom: 0.3rem;
}
#schoolRegistrationForm {
  padding-top: 2rem;
}
.textOnInput {
  position: relative;
}
.textOnInput label {
  position: absolute;
  top: -11px;
  left: 23px;
  padding: 2px;
  z-index: 1;
}
.textOnInput label:after {
  content: " ";
  background-color: #fff;
  width: 100%;
  height: 13px;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
}
label {
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 0.5rem;
  color: var(--input-lable-color);
}
.form_control {
  display: block;
  width: 100%;
  height: 54px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: var(--input-placeholder);
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
}
.form_control::file-selector-button {
  font-weight: bold;
  color: var(--input-placeholder);
  padding: 0.3em 0.7em;
  border: thin solid var(--input-placeholder);
  border-radius: 3px;
  margin-bottom: 1rem;
  font-weight: 500;
  font-size: 13px;
  background: var(--whitecolor);
  font-family: "Montserrat", sans-serif;
}

.form_control option {
  color: var(--input-placeholder);
}

.alreadyHaveAn {
  padding-top: 1rem;
  color: var(--black-color);
}
.alreadyHaveAn a {
  color: var(--lightOrange);
  text-decoration: none;
  font-weight: 600;
  padding-left: 2px;
}
.regionBox .textOnInput {
  width: 33.3%;
}
.regionBox {
  display: flex;
  gap: 1.2rem;
}
.captionPara {
  font-size: 13px;
  padding-top: 4px;
}
.poweredByH2sLogo {
  display: flex;
  align-items: center;
  text-align: right;
  justify-content: flex-end;
  padding-top: 1rem;
  font-size: 15px;
  font-weight: 500;
}
.poweredByH2sLogo img {
  padding-left: 7px;
}
.clearfix {
  clear: both;
}

@media (max-width: 1200px) {
  .rocketRight {
    z-index: 1;
  }
  .schoolRegistrationDiv {
    width: 95%;
    padding: 1.5rem 1.8rem;
  }
  .links {
    width: 22%;
  }
  .schoolRegistrationDiv::after {
    right: 0;
    width: 30%;
  }
}
.mobileHeading {
  display: none;

  padding-bottom: 1.5rem;
}

@media (max-width: 1023px) {
  .links {
    width: 100%;
    position: initial;
    float: none;
  }
  .social_media {
    width: 100%;
    float: none;
    padding: 0rem;
  }
  .desktopHeading {
    display: none;
  }
  .mobileHeading {
    display: block;
  }
  .mobileHeading h1 {
    color: var(--sih_blue);
    font-size: 1.5rem;
    padding-bottom: 0.6rem;
  }
  .poweredByH2sLogo {
    justify-content: center;
    font-size: 13px;
  }
  .poweredByH2sLogo img {
    width: 70px !important;
  }
}
@media (max-width: 768px) {
  .rocketRight {
    top: 13rem;
    width: 120px;
    z-index: 1;
  }
}
@media (max-width: 578px) {
  .rocketRight {
    top: 14rem;
    width: 90px;
  }
}

@media (max-width: 570px) {
  .schoolRegistrationDiv {
    padding: 1.2rem;
    margin-top: 5.5rem !important;
  }
  .mobileHeading h1 {
    font-size: 1.3rem;
  }
  .mobileHeading p {
    font-size: 0.8rem;
  }
  .rightSideNotice h5 {
    font-size: 1.3rem;
  }
  .rightSideNotice p {
    font-size: 0.8rem;
  }
  .rightSideNotice a {
    font-size: 0.7rem;
  }
  .greenBtn {
    width: 100%;
  }
  .regionBox {
    flex-direction: column;
    gap: 0rem;
  }
  .textOnInput {
    width: 100% !important;
  }
  .textOnInput label {
    left: 11px;
    top: -9px;
    font-size: 12px;
  }
  .mb2 {
    margin-bottom: 2rem;
  }
  .schoolRegistrationDiv::after {
    right: 0;
    width: 50%;
  }
}

.infoConatiner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2.5rem;
  font-size: large;
  text-align: center;
  flex-direction: column;
  height: 100%;
  gap: 1.5rem;
  font-size: large;
  font-weight: 600;
  line-height: 2rem;
}

.contactEmail {
  color: #419eff;
}

.goBack {
  background-color: var(--sih_blue);
  color: white;
  text-transform: uppercase;
  padding: 0.6rem 1rem;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: small;
  font-weight: 500;
  width: 250px;
}

.noteText {
  font-size: small;
}

/* CSS */
.modal {
  display: block;
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}
.modal iframe {
  height: 25rem;
}
.modal_content {
  background-color: #fefefe;
  margin: 10% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 600px;
  position: relative;
  height: auto;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.headerOfModal {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
  align-items: center;
}
@media (max-width: 600px) {
  .modal_content {
    margin: 18% auto;
  }
}
.progTitle {
  text-transform: lowercase;
}

.progTitle::first-letter {
  text-transform: uppercase;
}

.schoolCodeLegend{
  margin-left: 2.5px;
}