.rocketRight {
  position: absolute;
  right: 0;
  top: 10rem;
}
.pageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 5rem 0;
}
.formConatiner {
  position: relative;
  width: 80vw;
  background-color: white;
  box-shadow: 4px 0px 27px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 3rem;
}

.Heading {
  font-size: large;
  font-weight: 600;
}

.description {
  font-size: smaller;
  color: #8b8b8b;
  font-weight: 400;
  margin-top: -0.6rem;
}

.statConatiner {
  display: flex;
  padding: 5px;
  border-bottom: 1px solid #d9d9d9;
  width: fit-content;
  font-size: smaller;
}
.stat {
  margin: 6px;
  padding: 0 6px;
  display: flex;
  border-right: 1px solid #d9d9d9;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
}
.stat:last-child {
  border-right: none;
}
.statNumber {
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(246, 158, 69, 0.2);
  border-radius: 3px;
  color: var(--dark-orange);
}

.close {
  position: absolute;
  right: 2rem;
  top: 2rem;
}

.wizardContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.singleStepContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: smaller;
}

.numberContainer {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 0.497px solid #838383;
}
.singleStepContainer.active .numberContainer {
  border: 0.497px solid #4bae4f;
  background-color: #4bae4f;
  color: white;
}
.stepNameContainer {
  font-size: smaller;
}
.active .stepNameContainer {
  color: #4bae4f;
}

.stepDivider {
  width: 100px;
  border: 0.197px solid #c5c5c5;
}

.overallStepFormContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
}

.individualForm {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  width: 90%;
  position: relative;
}

.formGroup {
  display: flex;
  flex-direction: column;
  gap: 5px;
  /* min-width: 350px; */
  width: 48%;
  position: relative;
}
@media (max-width: 768px) {
  .formGroup {
    width: 100%;
  }
}
.formInput,
select {
  border-radius: 4.545px;
  border: 0.909px solid #d9d9d9;
  outline: none;
  padding: 0.6rem 1rem;
}

.formGroup label {
  background-color: white;
  padding: 6px;
  font-size: x-small;
  position: absolute;
  top: -14px;
  left: 8px;
}
.formGroup label span {
  color: red;
}

.buttonContainer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
}

.overallFormHeading {
  font-size: small;
  font-weight: 600;
  width: 100%;
}

.buttonConatiner {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.memberForm {
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  padding: 1rem;
}

.addAnother {
  border-radius: 4.541px;
  border: 0.908px dashed #afafaf;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.4rem 2rem;
  font-size: small;
  cursor: pointer;
  color: #afafaf;
}

.closeIndividualForm {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.full {
  width: 100%;
}

.letterDispaly {
  font-size: smaller;
}
.letterDispaly a {
  color: blue;

  text-decoration: underline;
}
