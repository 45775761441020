.dashboardTopDiv {
  background: url("https://saxenaprachi.github.io/publicAssets/images/dashboard-top-backgraund.png");
  background-size: cover;
  background-position: left;
  background-repeat: no-repeat;
  padding-top: 1rem;
  padding-bottom: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  gap: 1rem;
  width: 100%;
}
.fontAndWeight {
  font-weight: 600;
  /* font-size: 16px; */
  color: var(--black-color);
}
.rocketRight {
  position: absolute;
  right: 0;
  top: 10rem;
}
.breadCrum {
  color: var(--input-lable-color);
  padding: 0rem 3rem;
  padding-bottom: 1.6rem;
  font-weight: 500;
}
.breadCrum a {
  color: var(--input-lable-color);
  /* font-size: 15px; */
}
.col1 {
  display: flex;
}

.locationIcon svg {
  color: var(--black-color);
  margin-bottom: -5px;
  margin-right: 6px;
  font-size: 1rem;
}
.allTabsContentOuter {
  padding: 0 2rem;
}
.allTabsContentOuter h4 {
  padding-top: 1.3rem;
}
.horizontalLine {
  background-color: #d9d9d9;
  height: 1px;
  border: none;
  margin-top: 4px;
}
.tabs a {
  border: none;
  background: transparent;
  padding: 1rem 0rem;
  text-transform: uppercase;
  font-weight: 600;
  margin-right: 2rem;
}
.tabsOuterBox {
  background: var(--whitecolor);
  border-radius: 10px;
  box-shadow: 6px 1px 80px rgba(0, 0, 0, 0.08);
  padding: 1.5rem;
  margin-top: -7.5rem;
}
.tabs {
  padding-bottom: 0.6rem !important;
  padding: 0 2rem;
  overflow-x: auto;
  width: 100%;
  display: flex;
}
.tabs a {
  white-space: nowrap;
}
a.active {
  border-bottom: 3px solid var(--lightOrange) !important;
  color: var(--lightOrange);
}

.spocDashboard {
  margin-top: 4.2rem;
  font-size: small !important;
}
.threeCols {
  display: flex;
  width: 100%;
  color: var(--input-lable-color);
  padding: 0rem 6rem;
  /* padding-bottom: 2.8rem; */
}

.col1 {
  width: 46.3%;
}
.col2 {
  width: 33.3%;
}
.column .leftImage {
  width: 31%;
  margin-right: 1.6rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0.4rem;
  min-width: 180px;
}
.col1 img {
  width: 100%;
  border: 2px solid #ce257e;
}
.column h5 {
  color: #bdbdbd;
  /* font-size: 15px; */
  padding-bottom: 13px;
  font-weight: 600;
}
.column p {
  padding-bottom: 13px;
}

.col3 {
  position: relative;
  width: 25%;
}
.poweredByH2sLogo {
  text-align: right; /* Align content to the right */
  margin-top: auto; /* Push content to the bottom */
  display: flex;
  align-items: center;
  text-align: right;
  justify-content: flex-end;
  padding-top: 1rem;
  /* font-size: 15px; */
  font-weight: 500;
  position: absolute;
  bottom: 10px;
}
.poweredByH2sLogo img {
  padding-left: 7px;
}

.noticeText {
  background: #e8f0f9;
  color: #0188f4;
  display: flex;
  align-items: center;
  padding: 0.8rem 3rem;
}
.noticeText svg {
  /* font-size: 1.9rem; */
  padding-right: 5px;
}

.spocDasboardContent {
  padding: 3rem 5rem;
  margin-top: -3rem;
  background: var(--backgroundLightGray);
}
.verticleLine {
  margin-right: 3rem;
  border-left: 1px solid #d9d9d9;
}

/* Timeline Container */
.timeline {
  background: var(--primary-color);
  margin: 20px auto;
  padding: 20px;
}

/* Outer Layer with the timeline border */
.outer {
  border-left: 2px dashed #838383;
}

/* Card container */
.card {
  position: relative;
  margin: 0 0 20px 20px;
  padding: 10px;
  padding-top: 0px;
  background: transparent;
  color: var(--input-lable-color);
  border-radius: 8px;
  max-width: 100%;
}
.card ul {
  padding-left: 1.6rem;
}
.card ul li {
  line-height: 1.5rem;
}
.card ul li::marker {
  color: var(--sih_blue);
  /* font-size: 28px; */
}

.card ol {
  padding-left: 1.2rem;
  padding-top: 0.5rem;
}
.themes {
  display: flex;
  gap: 1.6rem;
  flex-wrap: wrap;
  margin-top: 1rem;
  /* justify-content: space-between; */
}

.themesCard {
  padding: 4rem 3.3rem;
  background: var(--whitecolor);
  text-align: center;
  box-shadow: 6px 1px 80px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}
/* Information about the timeline */
.info {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Title of the card */
.title {
  color: var(--input-placeholder);
  position: relative;
  /* font-size: 1rem; */
}

/* Timeline dot  */
.title::before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  background: #d9d9d9;
  border-radius: 999px;
  left: -43.9px;
  border: 5px solid #838383;
}
@media (max-width: 1460px) {
  .verticleLine {
    margin: 0rem 2rem;
  }
}

@media (max-width: 1400px) {
  .threeCols {
    padding: 0rem 3rem;
    padding-bottom: 3rem;
  }
}

.hrCol1Mobile {
  display: none;
}
@media (max-width: 1280px) {
  .towColumnContainer{
    flex-wrap: wrap;
  }
}
@media (max-width: 1200px) {
  .hrCol1Mobile {
    display: block;
    background-color: #d9d9d9;
    height: 2px;
    border: none;
    margin-bottom: 22px;
  }
  .verticleLine {
    display: none;
  }
  .breadCrum {
    padding: 0rem 1rem;
    padding-bottom: 1.6rem;
  }
  .threeCols {
    flex-direction: column;
  }
  .column {
    width: 100%;
    margin-bottom: 1.5rem;
  }
  .poweredByH2sLogo {
    position: relative;
    padding-top: 3.2rem;
  }
  .col1 {
    display: block;
  }
  .rocketRight {
    z-index: 1;
  }
  .col1 img {
    width: 65%;
    margin-bottom: 0.9rem;
  }
}

@media (max-width: 848px) {
  .statBlock{
    width: 100% !important;
  }
}
@media (max-width: 768px) {
  .threeCols {
    padding-bottom: 0;
  }
  .col3 {
    margin-bottom: 0rem;
  }
  .column .leftImage {
    width: 45%;
  }
  .col1 img {
    width: 70%;
    margin-bottom: 0.9rem;
  }
  .themes {
    gap: 1rem;
  }
  .rocketRight {
    top: 13rem;
    width: 120px;
    z-index: 1;
  }
  
}
@media (max-width: 767px) {
  .tabsOuterBox {
    padding: 1rem;
  }
  .spocDasboardContent {
    padding: 3rem 1.5rem;
  }
  .allTabsContentOuter {
    padding: 0 0rem;
  }
  .noticeText {
    /* font-size: 0.9rem; */
    padding: 0.8rem 1rem;
  }

  .poweredByH2sLogo {
    justify-content: flex-start;
  }
  .caption p{
    font-size: 0.6rem;
  }
  .submissionHeading{
    font-size: 0.69rem !important;
  }
  .horizontalBarChartContainer p::after {
    top: 1px !important;
    left: -15px !important;
  }
  .towColumnContainer{
    padding: 1rem 0rem !important;
  }
 
}

@media (max-width: 578px) {
  .rocketRight {
    top: 14rem;
    width: 90px;
  }
  .themesCard {
    padding: 5.7rem 5.3rem;
  }
  .timeline {
    padding: 5px;
  }
  .card ul {
    padding-left: 1.1rem;
  }
  .tabs {
    padding: 0 1rem;
  }
  .spocDasboardContent{
    padding: 3rem 0.5rem;
  }
}
@media (max-width: 420px) {
  .threeCols {
    padding: 0rem 1rem;
  }

  .poweredByH2sLogo {
    /* font-size: 0.9rem; */
    justify-content: flex-start;
  }
  .poweredByH2sLogo img {
    width: 75px;
  }
}

.towColumnContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  gap: 2rem;
  padding: 1rem;
}

@media (max-width: 1061px) {
  .towColumnContainer {
    flex-direction: column;
  }
}

.columnConatiner {
  width: 100%;
  border: 1px solid #bdbdbd;
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.totalTeams{
  font-size: 1.5rem;
  font-weight: bolder;
  color: var(--black-color);
}

.columnHeading {
  width: 100%;
  padding: 0.5rem;
  border-bottom: 1px solid #bdbdbd;
  font-weight: 700;
  font-size: 1rem;
}
.mainHeding{
  font-size: large;
  font-weight: 800;
  text-transform: uppercase;
}

.statBlockContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 1.3rem;
  padding: 2rem 0;
  justify-content: space-between;
}

.statBlock {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: center; */
  padding: 1rem;
  width: 48%;
  /* text-align: center; */
}
.progressBarBlock{
  width: 100% !important;
}
.HorizontalBarChart{
  width: 100% !important;
  text-align: center;
}
.horizontalBarChartContainer{
  width: 100%;
  height: 300px;
}
.category{
  font-size: small;
}
.totalTeams{
  display: flex;
  align-items: center;
  gap: 0.6rem;
  font-size: 0.9rem;
}
.totalTeams svg{
  font-size: 2.6rem;
}
.totalTeams svg path{
  fill: var(--sih_blue);
}
.totalTeamsCount{
  font-size: 2rem;
  font-weight: bold;
}
.registeredToday span{
  color: var(--light-green);
  font-weight: 700;
  padding-right: 5px;
}
.registeredToday svg path{
  fill: var(--light-green);
  font-weight: 700;
}
.submissionHeading{
  font-size: 0.9rem;
  font-weight: 700;
  margin-top: 0.4rem;
}
.submissionCircle{
  justify-content: space-between !important;
  padding: 1rem 0.1rem !important;
}
.submissionCircle svg{
  width: 45% !important;
  margin-right: auto;
}
.category p{
  position: relative;
  margin-bottom: 1rem;
}
.category p:first-child::after{
  content: "";
  position: absolute;
  top: 3px;
  left: -15px;
  width: 10px;
  height: 10px;
  background: var(--lightOrange);
  border-radius: 20px;
}
.category p:nth-child(2)::after{
  content: "";
  position: absolute;
  top: 3px;
  left: -15px;
  width: 10px;
  height: 10px;
  background: var(--sih_blue);
  border-radius: 20px;
}
.caption{
  margin: 1.5rem 0rem;
  font-size: 0.8rem;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.6rem;
}
.caption p{
  position: relative;
}
.caption p:first-child::after{
  content: "";
  position: absolute;
  top: 3px;
  left: -15px;
  width: 10px;
  height: 10px;
  background: var(--spocProgressbarColor3);
  border-radius: 20px;
}
.caption p:nth-child(2)::after{
  content: "";
  position: absolute;
  top: 3px;
  left: -15px;
  width: 10px;
  height: 10px;
  background: var(--sih_blue);
  border-radius: 20px;
}
.caption p:nth-child(3)::after{
  content: "";
  position: absolute;
  top: 3px;
  left: -15px;
  width: 10px;
  height: 10px;
  background: var(--spocProgressbarColor1);
  border-radius: 20px;
}
.horizontalBarChartContainer p{
  position: relative;
  width: fit-content;
  margin: auto;
  margin-bottom: 1rem !important;
  margin-top: 2rem !important;
}
.horizontalBarChartContainer p::after{
  content: "";
  position: absolute;
  top: 3.5px;
  left: -16px;
  width: 10px;
  height: 10px;
  background: var(--sih_blue);
  border-radius: 20px;
}

@media (max-width: 558px) {
  .statBlock {
    padding: 0.6rem;
  }
}

.statBlock.chart {
  width: 100%;
}

.ProgressConatiner {
  padding: 1rem;
  font-size: 50px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}

.progessCircle {
  width: 70% !important;
}
@media (max-width: 558px) {
  .progessCircle {
    width: 95% !important;
  }
}
.disabled {
  cursor: default !important;
  color: #838383 !important;
}
