.loaderOverlay {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  background-color: rgb(221, 221, 221);
  z-index: 5000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  background-color: rgba(255, 255, 255, 0.7);

  height: 100vh;
  width: 100vw;
}
