.header {
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: var(--whitecolor);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  z-index: 100;
  border-bottom: 2px solid var(--lightOrange);

  padding: 0.5rem 2rem;
}
.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.orangeBtn {
  background: var(--lightOrange);
  color: var(--whitecolor);
}

.rightSideDiv a {
  display: inline-block;
  margin: 0 1.5rem;
  color: var(--sih_blue);
  text-decoration: none !important;
  line-height: 3.5rem;
  font-weight: 500;
}

/* .header a:hover,
.header a:active {
	background: rgba(0, 0, 0, 0.2);
} */

.header a.logo {
  display: flex;
  align-items: center;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
}
.sihLogo {
  width: 300px;
}
.rightSideDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  /* space-x-3  */
  cursor: pointer;
}
.profileImageDiv {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  overflow: hidden;
}
.profileImage {
  width: 100%;
  height: 100%;
}
.iconDiv {
  margin: 0 1rem;
}

.navToggleDiv a {
  color: var(--toggle-color);
  font-size: 2rem;
}

@media (max-width: 1023.9px) {
  .rightSideDiv {
    /* border-bottom: 2px solid var(--lightOrange); */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    flex-direction: column;
    position: fixed;
    left: 0;
    top: 69.5px;
    background: var(--whitecolor);
    width: 100%;
    padding: 2rem;
  }
  .navToggleDiv a {
    font-size: 1.8rem;
    display: flex;
  }
}
@media (max-width: 600px) {
  .rightSideDiv {
    top: 55.9px;
  }
  .logo img {
    width: 300px;
  }
}
@media (max-width: 500px) {
  .logo img {
    width: 190px;
  }
  .navToggleDiv a {
    font-size: 1.5rem;
  }
  .rightSideDiv {
    top: 41.9px;
  }
}
.dropdown {
  display: flex;
  align-items: center;
}

.Toggle {
  display: none;
}

@media (max-width: 869px) {
  .Toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .sihLogo {
    width: 260px;
  }
  .rightContainer {
    display: none !important;
  }
}
.menuModal {
  justify-content: center;
  align-items: flex-start;
  background-color: white;
  border-radius: 8px;
  filter: drop-shadow(5px 0px 25px rgba(0, 0, 0, 0.15));
  position: absolute;
  right: 0px;
  top: 2.7rem;
  /* bottom: -6rem; */
  padding: 0.6rem;
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  font-size: small;
  min-width: 180px;
  width: max-content;
  z-index: 100;
}

.menuModal .menu {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.4rem;
  font-weight: 400;
}

.menuModal hr {
  width: 100%;
}

.rightContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1.2rem;
  font-size: 14px;
  color: var(--sih_blue) !important;
  font-weight: 600;
  position: relative;
  /* text-transform: uppercase; */
}
@media (max-width: 1027px) {
  .rightContainer {
    font-size: 10px;
  }
}
@media (max-width: 869px) {
  .rightContainer {
    font-size: 8px;
  }
}
a {
  text-decoration: none !important;
}

.orangeButton {
  background-color: var(--dark-orange);
  padding: 0.6rem 1rem;
  border-radius: 8px;
  color: white;
  text-transform: uppercase;
  border: 0;
  cursor: pointer;
  font-weight: 600;
}

.profile {
  display: flex;
  /* gap: 1rem; */
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.Logout {
  cursor: pointer;
}
