.main {
  display: flex;
  width: 100%;
}
.sideBarConatiner {
  width: 275px;
}
.mainContent {
  height: 97vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 2rem;
  padding-top: 90px;
  gap: 1.4rem;
  width: 100%;
}
.createAdminForm {
  background-color: white;
  padding: 1.5rem;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2rem;
}

.createForm {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
}
.inputGroup {
  position: relative;
  width: 100%;
  max-width: 520px;
}

.inputGroup label {
  position: absolute;
  top: -10px;
  left: 10px;
  background-color: white;
  font-size: smaller;
  color: var(--input-lable-color);
  padding: 0 5px;
}

.inputGroup input {
  width: 100%;
  max-width: 520px;
  border: 1.5px solid var(--gray-border-color);
  background-color: transparent;
  padding: 13px 1rem;
  border-radius: 6px;
}
.inputGroup input:focus {
  outline: 0;
}

.buttonContainer {
  width: 100%;
  display: flex;
  gap: 1rem;
}

.createButton {
  background-color: var(--lightOrange);
  color: white;
  padding: 0.6rem 1rem;
  border-radius: 5px;
  border: 0;
  outline: 0;
  cursor: pointer;
}
.cancelButton {
  color: var(--select-input-placeholder);
  padding: 0.6rem 1rem;
  border-radius: 5px;
  border: 2px solid var(--select-input-placeholder);
  outline: 0;
  background-color: white;
  cursor: pointer;
}
