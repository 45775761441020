.main {
  display: flex;
  width: 100%;
}
.sideBarConatiner {
  width: 275px;
}
.mainContent {
  /* height: 97vh; */
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 2rem;
  padding-top: 90px;
  gap: 1.4rem;
  width: 100%;
  padding-bottom: 5.5rem;
}
.createNoticeForm {
  width: 100%;
  display: flex;
  gap: 1.2rem;
  padding-bottom: 2.5rem;
}
.leftBox small {
  color: var(--input-placeholder);
}

.leftBox {
  background-color: white;
  box-shadow: 0px 0px 6px 2px #d9d9d9;
  padding: 1.5rem;
  border-radius: 10px;
  width: 30%;
  padding-bottom: 2.5rem;
}
.rightBox {
  background-color: white;
  box-shadow: 0px 0px 6px 2px #d9d9d9;
  padding: 1.5rem;
  border-radius: 10px;
  width: 100%;
  padding-bottom: 2.5rem;
}

.outerBox {
  box-shadow: 0px 0px 6px 2px #d9d9d9;
  border-radius: 10px;
  width: 100%;
  padding: 1rem;
}
.outerBox textarea {
  resize: none;
  width: 100%;
  margin-top: 1rem;
  border-radius: 10px;
  width: 100%;
  padding: 1rem;
  border: 1px solid #e8e8e8;
  outline: none;
}
.commonBox {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  margin-bottom: 1rem;
  width: 100%;
}
.commonBox label {
  font-size: 15px;
  font-weight: 700;
  width: 10%;
}
.outerBox .form_control {
  border-radius: 8px !important;
}
.tagBox {
  background: var(--whitecolor);
  box-shadow: 0px 0px 6px 2px #ededed;
  border-radius: 10px;
  width: 100%;
  padding: 1rem;
  margin: 1.1rem 0rem;
}
.tagBox h5 {
  color: var(--input-lable-color);
  padding-bottom: 0.4rem;
  line-height: 1.2rem;
}
.tagBox small {
  color: var(--linkColor);
  font-size: 0.7rem;
}
.ifverifiedSpocRepeatBox {
  display: flex;
  align-items: center;
  gap: 3rem;
  padding: 1rem;
  /* margin-bottom: 1rem; */
  width: 100%;
}
.ifverifiedSpocRepeatBox div {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.ifverifiedSpocRepeatBox label {
  font-weight: 700;
}
.actionButtons {
  gap: 1rem;
}
.tagsContainer {
  height: 80vh;
  margin: 0 -10px;
  overflow-y: auto;
}

.tagsContainer::-webkit-scrollbar {
  width: 16px;
}

.tagsContainer::-webkit-scrollbar-track {
  background-color: transparent;
}

.tagsContainer::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

.tagsContainer::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}
.PaginationConatiner{
  width: 100%;
  padding: 0.6rem 0rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.tableConatiner{
  width: 100%;
}