.active {
  /* color: var(--dark-orange) !important;
  font-weight: 600; */
  color: #fff;
  font-weight: 600;
  background: var(--lightOrange) !important;
  padding: 0.5rem 0.9rem;
  border-radius: 50%;
}
.prevNextBtn{
  color: #676666;
}

